const courses = [
    {
        category: "Safety Services",
        services: [
            "CHAS / Construction Line Assistance",
            "COSHH Assessments",
            "Fire Risk Assessments",
            "Health & Safety Audits",
            "Site Safety Audit / Investigation",
            "Health & Safety Policy",
            "Management of Health & Safety",
            "Method Statements",
            "Risk Assessment",
            "Site Safety Inspections"
        ]
    },
    {
        category: "Safety Courses",
        services: [
            "Abrasive Wheels",
            "Asbestos Awareness",
            "Banks-man",
            "Cable Avoidance (CAT)",
            "Chainsaws",
            "Confined Space Entry",
            "Fire Safety Awareness",
            "Fire Warden Training",
            "Harness Inspection and Use",
            "Hot-works Training",
            "IOSH Managing Safely",
            "Ladder Safety",
            "Manual Handling",
            "SPA / MPQC Quarry Safety Passport",
            "Safety Awareness Training",
            "Scaffold Inspection",
            "Scaffold Tower",
            "Street-works",
            "Trench Shoring",
            "Working at Heights",
        ]
    },
    {
        category: "First Aid",
        services: [
            "Automated External Defibrillator (AED)",
            "Basic Life Support Training",
            "Early Years (child) First Aid",
            "Emergency First Aid at Work",
            "First Aid at Work",
            "First Aid at Work Re-Qualification",
            "First Aid at Work Half Day Annual Update",
            "Food Safety Level 1",
            "Food Safety Level 2",
            "Health and Safety at Work Level 1",
            "Health and Safety at Work Level 2",
        ]
    },
    {
        category: "CPCS",
        services: [
            "180° Excavator All Sizes",
            "360° Excavator All Sizes",
            "Agricultural Tractor",
            "Dump Truck",
            "Forward Tipping Dumper",
            "Lorry Loader",
            "Ride of Roller",
            "Rough Terrain Forklift",
            "Skid Steer Loader",
            "Telescopic Handler",
            "Wheeled Loading Shovel"
        ]
    },
    {
        category: "CSCS",
        services: [
            "Site Management Level 4 - 6",
            "Site Supervisor Level 3",
            "Site Operative Level 2",
            "Construction Operations",
            "Health & Safety",
            "LGV Drivers",
            "Plant Operators",
            "Plant Maintenance",
            "Road-building Pavers/Planers",
            "Taxi Drivers",
            "Street-lighting Installation",
            "Warehouse & Distribution"
        ]
    },
    {
        category: "CSTS",
        services: [
            "360° Excavator All Sizes",
            "Agricultural Tractor",
            "Crusher /Screener",
            "Dump Truck",
            "Forward Tipping Dumper",
            "Hoists",
            "Industrial Counterbalance Forklift",
            "Landscape Machinery & Equipment",
            "Lorry Loader",
            "MEWPS",
            "Paver (Tarmacadam)",
            "Planer",
            "Quick-Hitch Attachments",
            "Ride of Roller",
            "Rough Terrain Forklift",
            "Skid Steer Loader",
            "Slinger / Signaller",
            "Telescopic Handler",
            "Wheeled Loading Shovel"
        ]
    },
    {
        category: "NPORS",
        services: [
            "180° Excavator All Sizes",
            "360° Excavator All Sizes",
            "360° Material Re-Handler",
            "Agricultural Tractor",
            "Asphalt Re-Instatement",
            "Cable Avoidance Tools",
            "Excavator as a Crane",
            "Forward Tipping Dumper",
            "Industrial Counterbalance Forklift",
            "Industrial Telescopic Handler",
            "Loading Shovel",
            "Lorry Loader",
            "Quick Hitch Awareness",
            "Rear Dump Truck",
            "Refuse Collection Vehicle",
            "Road Roller",
            "Rough Terrain Forklift",
            "Safety Awareness",
            "Safety at Street & Road Works",
            "Skid-steer Loader",
            "Slinger / Signaller",
            "Telescopic Handler",
            "Vehicle Marshall"
        ]
    },

];

export default courses;