import React, { Component, Fragment } from "react";
import logo from "./logo.svg";
import "./App.scss";
import { elastic as Menu } from "react-burger-menu";
import Gallery from "./gallery/galery";
import OurServices from './ourservices/ourservices';
import "./animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";


import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";

import "./mobileMenu.scss";
const fbLink="https://www.facebook.com/pages/biz/DL3-8DQ/Carmel-Safety-Training-Services-Ltd-444338465671876/";
const linkedinLink = "https://www.linkedin.com/company/carmel-safety-training-services-limited/about/";


var sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  arrows: true
};

function isIE() {
  var ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  
   return is_ie; 
  //let isIE = /*@cc_on!@*/false || !!document.documentMode;
  //return true;
}

class App extends Component {
  renderMobileMenu() {
    var isMenuOpen = function(state) {
      return state.isOpen;
    };

    // return (
    //   <div class="mobile-menu-container">
    //     <Menu
    //       right
    //       pageWrapId={"page-wrap"}
    //       outerContainerId={"outer-container"}
    //     >
    //       {this.menuItems()}
    //     </Menu>
    //   </div>
    //   //  <div className={s.menuContainer}> </div>
    // );

    return (<Menu
      right
      pageWrapId={"page-wrap"}
      outerContainerId={"outer-container"}
    >
      {this.menuItems()}
      <a href={fbLink}>Facebook</a>
      <a href={linkedinLink}>Linkedin</a>

    </Menu>);
  }

  menuItems() {
    var menuItem = (id, text) => {
      return (
        <li>
          <Link to={id} smooth={true} hashSpy={true} duration={500}>
            {text}
          </Link>
        </li>
      );
    };

    return (
      <Fragment>
        {menuItem("about", "Acreditors")}
        {menuItem("our-services", "Our services")}
        {menuItem("recommendations", "Reviews")}
        {menuItem("gallery", "Gallery")}
        {menuItem("contact", "Contact")}

      </Fragment>
    );
  }

  menuList() {
    return <ul>
      {this.menuItems()}
    </ul>
  }

  recCells() {
    const recCell = (title, text, place) => {
      return (
        <div className="rec-cell">
          <span className="main">{title}</span>

          <span className="text">{text}</span>

          <span className="place">{place}</span>
        </div>
      );
    };

    return [recCell(
      "Highly Recommended and a five star end to end service.",
      "The professionalism of this organisation is second to none. The friendly manner of instruction combined with obvious knowledge and considerable experience is evident throughout the training process. We will use this company for all our training in the future. We will be recommending Carmel Safety Training Services Ltd to our other business associates. ",
      "Effective Chartered Surveyors Ltd, Newton Aycliffe"
    ),

    recCell(
      "Highly Recommended.",
      "The professionalism of this organisation is second to none. The friendly manner of instruction combined with obvious knowledge and considerable experience is evident throughout.",
      "Eng MIET GCGI, Swindon"
    )]
  }

  render() {


    const about1Fragment = <Fragment>
 <span className="section-black-header">Few words</span>

<span className="section-blue-header">About company</span>

<hr />
<span>
  We have over 30 years in the Construction Industry and are
  committed to delivering a very high standard of training,
  covering a wide range of subjects. We can provide courses on
  Plant Instruction to Health & Safety aimed at Site Managers
  Level, along with Basic Plant Assessments through to Full
  Plant & Construction NVQs.
</span>
    </Fragment>;

    const about2Fragment = <Fragment>
<span className="section-black-header">Companies</span>
                <span className="section-blue-header">Acreditors</span>

                <hr />
                <div className="acreditators-container">
                  
                  <img src="/images/logo2.png" />
                  <img src="/images/logo3.png" />
                  <img src="/images/logo4.png" />
                  <img src="/images/logo5.png" />
                </div>
    </Fragment>;
   

    return (
      <div className="App" id="outer-container">
        {this.renderMobileMenu()}
        <main id="page-wrap">
          <header className="App-header">
            <nav>
              <img src="/images/main-logo.png" />
              <div className="menu top-menu">
                {this.menuList()}

                <div className="social">
                  <a href={fbLink}>Facebook</a>
                  <span className="break"></span>
                  <a href={linkedinLink}>Linkedin</a>
                </div>
              </div>
            </nav>
            <h1 className="fadeIn animated slower">
              Construction, Plant <br />
              & Safety Training <br />
              Specialists
            </h1>
          </header>
          <div id="about" class="about">
            <div>
            
              <div>
              
                {/* <span className="section-black-header">Few words</span>

                <span className="section-blue-header">About company</span>

                <hr />
                <span className="long-text">
                  We have over 30 years in the Construction Industry and are
                  committed to delivering a very high standard of training,
                  covering a wide range of subjects. We can provide courses on
                  Plant Instruction to Health & Safety aimed at Site Managers
                  Level, along with Basic Plant Assessments through to Full
                  Plant & Construction NVQs.
                </span> */}
                
             {
               isIE() ? about1Fragment
               : <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>{about1Fragment} </ScrollAnimation>
             }
               
                
              </div>
             
            </div>

            <div>
              <div>
              
              {
               isIE() ? about2Fragment
               : <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>{about2Fragment} </ScrollAnimation>
             }
                
                
              {/* <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
                <span className="section-black-header">Companies</span>
                <span className="section-blue-header">Acreditors</span>

                <hr />
                <div className="acreditators-container">
                  <img src="/images/logo1.png" />
                  <img src="/images/logo2.png" />
                  <img src="/images/logo3.png" />
                  <img src="/images/logo4.png" />
                  <img src="/images/logo5.png" />
                </div>
                </ScrollAnimation> */}
              </div>
            </div>
          </div>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div id="our-services">
          <span className="what-we-do">What we do</span>
            <span className="our-services-header">Our services</span>
            <OurServices />
            </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
          <div id="recommendations" >
            <span className="few-words">Few words</span>
            <span className="recommendations-header">Recommendations</span>
            <hr />

            <div className="rec-container rec-container-mobile">
            <Slider {...sliderSettings}>
{this.recCells()}
            </Slider>
            </div>

            <div className="rec-container rec-container-desktop">
              {this.recCells()}
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="jackInTheBox" animateOnce={true}>
          <div id="gallery">
            <span className="beautiful"></span>
            <span className="image-gallery">Gallery</span>
            <div className="gallery-comp-container">
              <Gallery />
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div id="contact">
             <span className="contact">Contact details</span>
             <span className="keep"><a href="mailto:
                            &#080;&#097;&#117;&#108;&#064;&#067;&#097;&#114;&#109;&#101;&#108;&#045;&#083;&#097;&#102;&#101;&#116;&#121;&#046;&#099;&#111;&#046;&#117;&#107;">
                            &#080;&#097;&#117;&#108;&#064;&#067;&#097;&#114;&#109;&#101;&#108;&#045;&#083;&#097;&#102;&#101;&#116;&#121;&#046;&#099;&#111;&#046;&#117;&#107;
                        </a>
                            <br /><a href="tel:01325463500">Phone: 01325 463500</a>
                            <br />
                            <br /> Or use the form below</span>
            <hr />
            <form method="POST" action="https://formspree.io/paul@carmel-safety.co.uk">
              <input type="text" name="name" placeholder="Name" />
              <input type="email" name="email" placeholder="Email" />
              <textarea name="message" rows="10" placeholder="Message"/>
              <button type="submit">Send</button>
            </form>
          </div>
          </ScrollAnimation>
          <footer>
            <img src="/images/main-logo.png" />

            <div className="menu bottom-menu ">
              {this.menuList()}

              <div className="social">
               Copyright {new Date().getFullYear()} by: &nbsp; <strong>Carmel Safety</strong>
              </div>
            </div>
          </footer>
        </main>
      </div>
    );
  }
}

export default App;
