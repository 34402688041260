import ImageGallery from 'react-image-gallery';
import React, { Component, Fragment } from 'react';
import './gallery.scss';
 
export default class MyComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      thumbnailPosition: 'bottom',
      showVideo: {},
    };
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  
  
  _renderVideo(item) {
    return (
      <div className='image-gallery-image'>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
                <a
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </a>
                <iframe
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe>
            </div>
          :
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img src={item.original}/>
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
 
      const images = [
          {
              original: '/gallery/960/9t.png',
              thumbnail: '/gallery/150/9t.png',
              embedUrl: '/gallery/video/9.mp4',
              renderItem: this._renderVideo.bind(this)
          },
      {
        original: '/gallery/960/1t.png',
        thumbnail: '/gallery/150/1t.png',
        embedUrl: '/gallery/video/1.mp4',
        renderItem: this._renderVideo.bind(this)
      },
      {
        original: '/gallery/960/2t.png',
        thumbnail: '/gallery/150/2t.png',
        embedUrl: '/gallery/video/2.mp4',
        renderItem: this._renderVideo.bind(this)
      },
      {
        original: '/gallery/960/3t.png',
        thumbnail: '/gallery/150/3t.png',
        embedUrl: '/gallery/video/3.mp4',
        renderItem: this._renderVideo.bind(this)
      },
      {
        original: '/gallery/960/4t.png',
        thumbnail: '/gallery/150/4t.png',
        embedUrl: '/gallery/video/4.mp4',
        renderItem: this._renderVideo.bind(this)
      },
      {
        original: '/gallery/960/5t.png',
        thumbnail: '/gallery/150/5t.png',
        embedUrl: '/gallery/video/5.mp4',
        renderItem: this._renderVideo.bind(this)
      },
      // IMAGES
      {
        original: '/gallery/960/1.jpeg',
        thumbnail: '/gallery/150/1.jpeg',
      },
      {
        original: '/gallery/960/2.jpeg',
        thumbnail: '/gallery/150/2.jpeg',
      },
      {
        original: '/gallery/960/3.jpeg',
        thumbnail: '/gallery/150/3.jpeg',
      },
      {
        original: '/gallery/960/4.jpeg',
        thumbnail: '/gallery/150/4.jpeg',
      },
      {
        original: '/gallery/960/5.jpeg',
        thumbnail: '/gallery/150/5.jpeg',
      },
      {
            original: '/gallery/960/6t.png',
            thumbnail: '/gallery/150/6t.png',
      },
      {
            original: '/gallery/960/7t.png',
            thumbnail: '/gallery/150/7t.png',
      },
      {
            original: '/gallery/960/8t.png',
            thumbnail: '/gallery/150/8t.png',
      },
    ]
 
    return (
      <ImageGallery items={images}
      onSlide={this._onSlide.bind(this)}
      />
    );
  }
 
}