import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import './ourservices.scss';
import courses from './courses';
import { CSSGrid, layout, SpringGrid, measureItems, makeResponsive } from 'react-stonecutter';

const Grid1 = measureItems(SpringGrid);
const Grid = makeResponsive(Grid1, { maxWidth: 750,   minPadding: 10, defaultColumns: 2 })

export default class MyComponent extends React.Component {

    componentDidMount() {
        this.categoryClicked(0);

    }

    categoryClicked(index) {
        var activeCat = courses[index];
        this.setState({
            activeCategory: activeCat.category,
            activeCategoryItems: activeCat.services
        })
    }

    render() {
        if (!this.state) return <div />;

        let { activeCategory, activeCategoryItems } = this.state;

        if (!activeCategory) return <div />;
        var allCategories = courses.map(x => x.category);

        return (
            <div className="services-container">
                <div className="menu-part">

                    {allCategories.map((c, i) => {
                        return <div key={c} onClick={() => this.categoryClicked(i)}
                            className={cx({ active: c === activeCategory })}
                        >
                            {c}
                        </div>
                    })}
                </div>

                {/* <div className="services-part">
                    {activeCategoryItems.map(c => {
                        return <div key={c}>
                            {c}
                        </div>
                    })}
                 */}

 <div className="items-part">
                {/* <Grid
                    component="div"
                    columnWidth={300}
                    gutterWidth={10}
                    gutterHeight={5}
                    layout={layout.simple}
                    duration={800}
                    easing="ease-out"
                >
                    {activeCategoryItems.map(c => {
                        // return <li key={c}>
                        // <div key={c}>
                        //     {c}
                        //     </div>
                        // </li>

                        return <div className="grid-cell" key={c} itemWidth={200} itemHeight={60}>
                        <span>
                            {c}
                            </span>
                        </div>;

                    })}
                </Grid>  */}

                <div className="own-grid">
{activeCategoryItems.map(c => {
    // return <li key={c}>
    // <div key={c}>
    //     {c}
    //     </div>
    // </li>

    return <div className="grid-cell" key={c}>
    <span>
        {c}
        </span>
    </div>;

})}
                    </div>
                </div>
            </div>
        );
    }

}